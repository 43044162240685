// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

//export const updateData = createAsyncThunk('api/updateServiceMainType', async payloadData => {
//    const response = await axios.post('/api/location', payloadData.data)
//    return response
//})

//export const addData = createAsyncThunk('api/AddServiceMainType', async payloadData => {
//    const response = await axios.post('/api/location', payloadData.data)
//    return response
//})

// For Listing
export const getData = createAsyncThunk('api/servicemaintype/view', async params => {
    const response = await axios.get('/api/servicemaintype/view', params)
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

// For Edit
//export const fetchSingleRecord = createAsyncThunk('api/location/fetch', async params => {
//    console.log(params)
//    const response = await axios.get(`/api/location/${params}`)
//    console.log(response.data)
//    return {
//        data: response.data
//    }
//})

export const appServiceMainTypeSlice = createSlice({
    name: 'appServiceMainType',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getData.fulfilled]: (state, { payload }) => {
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        }
        //[fetchSingleRecord.fulfilled]: (state, { payload }) => {
        //    state.dtoData = payload.data
        //    state.updated = 0
        //},
        //[addData.fulfilled]: (state) => {
        //    state.updated = 1
        //},
        //[updateData.fulfilled]: (state) => {
        //    state.updated = 1
        //}
    }
})

export default appServiceMainTypeSlice.reducer
