// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import portfolio from '@src/views/modules/core/portfolio/store'
import building from '@src/views/modules/core/building/store'
import equipment from '@src/views/modules/core/equipment/store'
import equipmentgroup from '@src/views/modules/core/equipmentgroup/store'
import equipmenttype from '@src/views/modules/core/equipmenttype/store'
import equipmentmaintype from '@src/views/modules/core/equipmentmaintype/store'
import service from '@src/views/modules/core/service/store'
import servicetype from '@src/views/modules/core/servicetype/store'
import servicemaintype from '@src/views/modules/core/servicemaintype/store'
import maintype from '@src/views/modules/core/maintype/store'
import uom from '@src/views/modules/core/uom/store'
import user from '@src/views/modules/core/user/store'
import technician from '@src/views/modules/core/technician/store'
import location from '@src/views/modules/core/location/store'
import facility from '@src/views/modules/core/facility/store'
import datasource from '@src/views/modules/core/datasource/store'
import make from '@src/views/modules/rmms/make/store'
import mediatype from '@src/views/modules/rmms/mediatype/store'
import equipmenttaskandschedule from '@src/views/modules/rmms/equipmenttaskandschedule/store'
import model from '@src/views/modules/rmms/model/store'
import requestdescription from '@src/views/modules/rmms/requestdescription/store'
import servicerequest from '@src/views/modules/rmms/servicerequest/store'
import shift from '@src/views/modules/rmms/shift/store'
import skill from '@src/views/modules/rmms/skill/store'
import task from '@src/views/modules/rmms/task/store'
import team from '@src/views/modules/rmms/team/store'
import teamstosystemtype from '@src/views/modules/rmms/teamstosystemtype/store'
import teamdetail from '@src/views/modules/rmms/teamdetail/store'
import weekoff from '@src/views/modules/rmms/weekoff/store'
import workorder from '@src/views/modules/rmms/workorder/store'
import dashboardinfo from '@src/views/modules/rmms/dashboard/dashboardinfo/store'
import servicerequestopenedfortheweek from '@src/views/modules/rmms/dashboard/graphs/servicerequestopenedfortheweek/store'
import servicerequestopenedmonthly from '@src/views/modules/rmms/dashboard/graphs/servicerequestopenedmonthly/store'
import overduepmsmonthly from '@src/views/modules/rmms/dashboard/graphs/overduepmsmonthly/store'
import responsewithin10mins from '@src/views/modules/rmms/dashboard/graphs/responsewithin10mins/store'
import responsewithinsameday from '@src/views/modules/rmms/dashboard/graphs/responsewithinsameday/store'
import timetorespond from '@src/views/modules/rmms/dashboard/graphs/timetorespond/store'
import workorderdocument from '@src/views/modules/rmms/workorderdocument/store'
import ppmschedule from '@src/views/modules/rmms/ppmschedule/store'
import severity from '@src/views/modules/rmms/severity/store'
import priority from '@src/views/modules/rmms/priority/store'
import floor from '@src/views/modules/core/floor/store'
import area from '@src/views/modules/core/area/store'
import room from '@src/views/modules/core/room/store'
import scheduletask from '@src/views/modules/rmms/scheduletask/store'
import equipmentschedule from '@src/views/modules/rmms/equipmentschedule/store'

import designation from '@src/views/modules/core/designation/store'

const rootReducer = {
    auth,
    todo,
    chat,
    email,
    users,
    navbar,
    layout,
    invoice,
    calendar,
    ecommerce,
    portfolio,
    building,
    equipment,
    equipmentgroup,
    equipmenttype,
    equipmentmaintype,
    uom,
    user,
    technician,
    location,
    facility,
    datasource,
    service,
    servicetype,
    servicemaintype,
    maintype,
    make,
    equipmenttaskandschedule,
    model,
    requestdescription,
    servicerequest,
    shift,
    skill,
    task,
    team,
    teamstosystemtype,
    teamdetail,
    weekoff,
    workorder,
    dashboardinfo,
    servicerequestopenedfortheweek,
    servicerequestopenedmonthly,
    overduepmsmonthly,
    responsewithin10mins,
    responsewithinsameday,
    timetorespond,
    workorderdocument,
    ppmschedule,
    severity,
    priority,
    floor,
    area,
    room,
    scheduletask,
    equipmentschedule,
    dataTables,
    permissions,

    designation,
    mediatype
}

export default rootReducer
