// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const updateData = createAsyncThunk('api/updateUser', async payloadData => {
    console.log("Updating data")
    const response = await axios.post('/api/user', payloadData.data)
    return response
})

export const addData = createAsyncThunk('api/AddUser', async payloadData => {
    console.log("Adding data")
    console.log(payloadData.data)
    const response = await axios.post('/api/user', payloadData.data)
    return response
})

// For Listing
export const getData = createAsyncThunk('api/user/view', async params => {
    const response = await axios.get('/api/user/view', params)
    console.log(response.data)
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

// For selectable picklist
export const getActiveUsers = createAsyncThunk('api/user/view', async params => {
    const response = await axios.get('/api/user/view', params)
    response.data = response.data.filter(item => {
        return Object.values(item)[3]?.toLowerCase().includes("yes")
    })
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

// For selectable picklist
export const getActiveUsersWithNoOne = createAsyncThunk('api/user/view', async params => {
    const response = await axios.get('/api/user/view', params)
    response.data = response.data.filter(item => {
        return Object.values(item)[3]?.toLowerCase().includes("yes")
    })
    response.data.unshift({ Id: 0, Name: 'No one', Group: 'Operator', Active: 'Yes' })
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

// For Edit
export const fetchSingleRecord = createAsyncThunk('api/user/fetch', async params => {
    console.log(params)
    const response = await axios.get(`/api/user/${params}`)
    console.log(response.data)
    return {
        data: response.data
    }
})

export const appUserSlice = createSlice({
    name: 'appUser',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getData.fulfilled]: (state, { payload }) => {
            console.log("get data fulfilled")
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        },
        [fetchSingleRecord.fulfilled]: (state, { payload }) => {
            console.log("fetch data fulfilled")
            state.dtoData = payload.data
            state.updated = 0
        },
        [addData.fulfilled]: (state) => {
            console.log("add data fulfilled")
            state.updated = 1
        },
        [updateData.fulfilled]: (state) => {
            console.log("update data fulfilled")
            state.updated = 1
        }
    }
})

export default appUserSlice.reducer
