// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// For Dashboard
export const getPercentageOfResponseWithin10Mins = createAsyncThunk('api/workorder/GetPercentageOfResponseWithin10Mins', async params => {
    const response = await axios.get(`/api/workorder/GetPercentageOfResponseWithin10Mins/${params}`)
    const payload = [response.data]
    return {
        allData: payload
    }
})

export const appResponseWithin10MinsSlice = createSlice({
    name: 'appResponseWithin10Mins',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getPercentageOfResponseWithin10Mins.pending]: (state) => {
            state.dtoData = {}
            state.allData = []
            state.updated = 0
        },
        [getPercentageOfResponseWithin10Mins.fulfilled]: (state, { payload }) => {
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 1
        }
    }
})

export default appResponseWithin10MinsSlice.reducer
