// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const updateData = createAsyncThunk('api/updateServicerequest', async payloadData => {
    const response = await axios.post('/api/servicerequest', payloadData.data)
    return response
})

export const addData = createAsyncThunk('api/AddServicerequest', async payloadData => {
    const response = await axios.post('/api/servicerequest', payloadData.data)
    return response
})

const reformatDateTime = (dateTime) => {
    const tempArray = dateTime.toString().split("T")
    const firstHalfArray = tempArray[0].split("-")
    const firstHalf = `${firstHalfArray[2]}-${firstHalfArray[1]}-${firstHalfArray[0]}`

    let secondHalf = tempArray[1].split(".")[0]
    const secondHalfArray = secondHalf.split(":")
    let hours = parseInt(secondHalfArray[0])
    if (hours >= 12) secondHalfArray.push("pm")
    else secondHalfArray.push("am")
    if (hours > 12) hours -= 12

    if (hours < 10) secondHalfArray[0] = `0${hours.toString()}`
    else secondHalfArray[0] = `${hours.toString()}`

    secondHalf = `${secondHalfArray[0]}:${secondHalfArray[1]}:${secondHalfArray[2]} ${secondHalfArray[3]}`
    const newDateTime = firstHalf.concat(" ", secondHalf)
    return newDateTime
}

const cleanUpData = (value) => {
    value["Date"] = reformatDateTime(value["Date"])
}

// For Listing
export const getData = createAsyncThunk('api/servicerequest/view', async params => {
    const response = await axios.get('/api/servicerequest/view', params)
    response.data.forEach(cleanUpData)
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

// For Edit
export const fetchSingleRecord = createAsyncThunk('api/servicerequest/fetch', async params => {
    console.log(params)
    const response = await axios.get(`/api/servicerequest/${params}`)
    console.log(response.data)
    return {
        data: response.data
    }
})

export const appServicerequestSlice = createSlice({
    name: 'appServicerequest',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getData.fulfilled]: (state, { payload }) => {
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        },
        [fetchSingleRecord.fulfilled]: (state, { payload }) => {
            state.dtoData = payload.data
            state.updated = 0
        },
        [addData.fulfilled]: (state) => {
            state.updated = 1
        },
        [updateData.fulfilled]: (state) => {
            state.updated = 1
        }
    }
})

export default appServicerequestSlice.reducer
