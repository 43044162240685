// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const updateData = createAsyncThunk('api/updateSeverity', async payloadData => {
    console.log("Updating data")
    const response = await axios.post('/api/severity', payloadData.data)
    return response
})

export const addData = createAsyncThunk('api/AddSeverity', async payloadData => {
    console.log("Adding data")
    console.log(payloadData.data)
    const response = await axios.post('/api/severity', payloadData.data)
    return response
})

// For Listing
export const getData = createAsyncThunk('api/severity/view', async params => {
    const response = await axios.get('/api/severity/view', params)
    console.log(response.data)
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

// For Edit
export const fetchSingleRecord = createAsyncThunk('api/severity/fetch', async params => {
    console.log(params)
    const response = await axios.get(`/api/severity/${params}`)
    console.log(response.data)
    return {
        data: response.data
    }
})

export const appSeveritySlice = createSlice({
    name: 'appSeverity',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getData.fulfilled]: (state, { payload }) => {
            console.log("get data fulfilled")
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        },
        [fetchSingleRecord.fulfilled]: (state, { payload }) => {
            console.log("fetch data fulfilled")
            state.dtoData = payload.data
            state.updated = 0
        },
        [addData.fulfilled]: (state) => {
            console.log("add data fulfilled")
            state.updated = 1
        },
        [updateData.fulfilled]: (state) => {
            console.log("update data fulfilled")
            state.updated = 1
        }
    }
})

export default appSeveritySlice.reducer
