// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useState, useEffect } from "react"

// ** Axios Imports
import axios from "axios"

export const updateData = createAsyncThunk(
  "api/updateEquipment",
  async (payloadData) => {
    console.log(payloadData.data)
    const response = await axios.post("/api/equipment", payloadData.data)
    return response
  }
)

export const updateMediaData = createAsyncThunk(
  "api/updateMedia",
  async (payloadData) => {
    // console.log(payloadData.data)
    const response = await axios.post("/api/media", payloadData.data)
    return response
  }
)
export const addData = createAsyncThunk(
  "api/AddEquipment",
  async (payloadData) => {
    // console.log(payloadData)
    const response = await axios.post("/api/equipment", payloadData.data)
    return response
  }
)
// For updating equipment picklist's options if location were to change
export const getJournalistData = createAsyncThunk(
  "api/equipment/view",
  async (params) => {
    const response = await axios.get("/api/equipment/view")
    // console.log(response.data)
    //console.log(params.folder)
    //  console.log(params.filteredLabels)

    function isInFolder(email) {
      if (params.folder === undefined) return true
      if (params.folder === "journalist") return email.IsEquipment
      if (params.folder === "media") return email.IsEquipment === false
      if (params.folder === "starred") return email.IsTriggerAlarm
      return false
    }
    const filteredData = response.data.filter((email) => {
      return (
        // isInFolder(email) &&
        (email.FirstName.toLowerCase().includes(params.q.toLowerCase())
          || email.LastName?.toLowerCase().includes(params.q.toLowerCase())
          || email.Email?.toLowerCase().includes(params.q.toLowerCase())
        )
      )
    })

    const emailsMeta = {
      journalist: response.data.filter(
        (email) => !email.isDeleted && email.IsEquipment === true
      ).length,
      media: response.data.filter(
        (email) => !email.isDeleted && email.IsEquipment === false && (email.ParentID === null || email.ParentID === undefined)
      ).length,
      department: response.data.filter(
        (email) => !email.isDeleted && email.IsEquipment === false && (email.ParentID !== null && email.ParentID !== undefined)
      ).length,
      general: response.data.filter(
        (email) => !email.isDeleted && email.IsEquipment === false && (email.IsTriggerAlarm === true)
      ).length,
      starred: 0
      // starred: response.data.emails.filter((email) => email.isStarred).length
    }
    return {
      params,
      allJournalistData: filteredData,
      allJournalistDataDDL: response.data,
      emailsMeta: emailsMeta
    }
  }
)

export const search = createAsyncThunk(
  "api/equipment/search",
  async (params) => {

    return {
      params
    }
  }
)

export const filterTags = createAsyncThunk(
  "api/equipment/filter",
  async (params) => {
    return {
      params
    }
  }
)
export const blocking = createAsyncThunk(
  "blocking",
  async () => {
    return true
  }
)
export const getMails = createAsyncThunk(
  "appEmail/getMails",
  async (params) => {
    const response = await axios.get("/apps/email/emails", { params })
    return {
      params,
      data: response.data
    }
  }
)

export const updateMails = createAsyncThunk(
  "appEmail/updateMails",
  async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
    const response = await axios.post("/apps/email/update-emails", {
      emailIds,
      dataToUpdate
    })
    await dispatch(getMails(getState().email.params))
    return {
      emailIds,
      dataToUpdate,
      data: response.data
    }
  }
)

export const updateMailLabel = createAsyncThunk(
  "appEmail/updateMailLabel",
  async ({ emailIds, label }, { dispatch, getState }) => {
    const response = await axios.post("/apps/email/update-emails-label", {
      emailIds,
      label
    })
    await dispatch(getMails(getState().email.params))
    return response.data
  }
)

export const paginateMail = createAsyncThunk(
  "appEmail/paginateMail",
  async ({ dir, emailId }) => {
    const response = await axios.get("/apps/email/paginate-email", {
      params: { dir, emailId }
    })
    return response.data
  }
)

export const selectCurrentMail = createAsyncThunk(
  "appEmail/selectCurrentMail",
  async (params) => {
    if (params.selectedFolder === "journalist") {
      const response = await axios.get("/api/equipment/" + params.id)
      // console.log(response.data)
      return {
        folder: params.selectedFolder,
        data: response.data
      }
    } else {
      const response = await axios.get("/api/media/" + params.id)
      // console.log(response.data)
      return {
        folder: params.selectedFolder,
        data: response.data
      }

    }
  }
)

export const appEmailSlice = createSlice({
  name: "appEmail",
  initialState: {
    allJournalistData: [],
    allMediaData: [],
    selectedFolder: [],
    mails: [],
    params: {},
    q: "",
    emailsMeta: {},
    selectedMails: [],
    allFilteredData: [],
    allMediaDataDDL: [],
    allDepartmentData: [],
    allGeneralData: [],
    allJournalistDataDDL: [],
    currentMail: null,
    block: false
  },
  reducers: {
    selectMail: (state, action) => {
      const selectedMails = state.selectedMails
      if (!selectedMails.includes(action.payload)) {
        selectedMails.push(action.payload)
      } else {
        selectedMails.splice(selectedMails.indexOf(action.payload), 1)
      }
      state.selectedMails = selectedMails
    },
    selectAllMail: (state, action) => {
      const selectAllMailsArr = []
      if (action.payload) {
        selectAllMailsArr.length = 0
        state.mails.forEach((mail) => selectAllMailsArr.push(mail.id))
      } else {
        selectAllMailsArr.length = 0
      }
      state.selectedMails = selectAllMailsArr
    },
    resetSelectedMail: (state) => {
      state.selectedMails = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(blocking.pending, (state, action) => {
        state.block = true
      })
      .addCase(search.fulfilled, (state, action) => {
        // console.log(action.payload.params.q)
        state.q = action.payload.params.q
        //  console.log(action.payload.params.folder)       
        if (action.payload.params.folder === "journalist") {
          if (action.payload.params.q.substring(0, 1) === "#") {
            const filteredData = state.allJournalistDataDDL.filter((email) => {
              return (email.TagCode.split(",").some(x => {
                return action.payload.params.sector.some(obj => {
                  return (obj.Id.toString() === x && obj.Make.toLowerCase().includes(action.payload.params.q.toLowerCase().slice(1)))
                })
              })
              )
            })
            state.allJournalistData = filteredData
          } else if (action.payload.params.q === "") {
            state.allJournalistData = state.allJournalistDataDDL
          } else {
            const filteredData = state.allJournalistDataDDL.filter((email) => {
              return (
                (email.FirstName.toLowerCase().includes(action.payload.params.q.toLowerCase())
                  || email.LastName?.toLowerCase().includes(action.payload.params.q.toLowerCase())
                  || email.Email?.toLowerCase().includes(action.payload.params.q.toLowerCase()
                  )
                ) || email.Address2.split(",").some(x => {
                  return action.payload.params.tags.some(obj => {
                    return (obj.Id.toString() === x && obj.Skill.toLowerCase().includes(action.payload.params.q.toLowerCase()))
                  })
                })
              )
            })
            state.allJournalistData = filteredData
          }
        } else if (action.payload.params.folder === "media") {
          const filteredData = state.allMediaDataDDL.filter((email) => {
            return (
              (email.FirstName.toLowerCase().includes(action.payload.params.q.toLowerCase())
                || email.LastName?.toLowerCase().includes(action.payload.params.q.toLowerCase())
                || email.Email?.toLowerCase().includes(action.payload.params.q.toLowerCase())
              ) && (email.ParentID === null || email.ParentID === undefined)
            )
          })

          state.allMediaData = filteredData
        } else if (action.payload.params.folder === "department") {
          const filteredData = state.allMediaDataDDL.filter((email) => {
            return (
              (email.FirstName.toLowerCase().includes(action.payload.params.q.toLowerCase())
                || email.LastName?.toLowerCase().includes(action.payload.params.q.toLowerCase())
                || email.Email?.toLowerCase().includes(action.payload.params.q.toLowerCase())
              ) && (email.ParentID !== null && email.ParentID !== undefined)
            )
          })
          state.allDepartmentData = filteredData
        } else if (action.payload.params.folder === "starred") {
          console.log("FILL GENEATE")
          const filteredData = state.allMediaDataDDL.filter((email) => {
            return (
              (email.FirstName.toLowerCase().includes(action.payload.params.q.toLowerCase())
                || email.LastName?.toLowerCase().includes(action.payload.params.q.toLowerCase())
                || email.Email?.toLowerCase().includes(action.payload.params.q.toLowerCase())
              ) && (email.IsTriggerAlarm === true)
            )
          })
          state.allGeneralData = filteredData
        }
      })
      .addCase(filterTags.fulfilled, (state, action) => {
        console.log(action.payload.params.folder)
        console.log(action.payload.params.tags)
        if (action.payload.params.folder === "journalist") {
          const filteredData = state.allJournalistDataDDL.filter((email) => {
            return (
              (action.payload.params.tags.length === 0 && (email.FirstName.toLowerCase().includes(action.payload.params.q.toLowerCase())
                || email.LastName?.toLowerCase().includes(action.payload.params.q.toLowerCase())
                || email.Email?.toLowerCase().includes(action.payload.params.q.toLowerCase()
                )
              )) || (action.payload.params.tags.length > 0 && email.Address2.split(",").some(x => {
                console.log("x", x)
                return action.payload.params.tags.some(obj => {
                  console.log("obj", obj)
                  return (obj.toString() === x)
                })
              })
              )
            )
          })
          state.allJournalistData = filteredData
        }
      })
      .addCase(updateData.fulfilled, (state, action) => {
        //console.log(action.payload.data)
        if (action.payload.data.Id === 0 &&
          action.payload.data.IsEquipment === false) {
          const allJournalistTemp = state.allJournalistData

          allJournalistTemp.push(action.payload.data)

          state.allJournalistData = allJournalistTemp
        }
        if (action.payload.data.Id === 0 &&
          action.payload.data.IsEquipment === true) {
          const allJournalistTemp = state.allMediaData

          allJournalistTemp.push(action.payload.data)

          state.allMediaData = allJournalistTemp
        }
        function updateMailData(email) {
          Object.assign(email, action.payload.data)
        }
        state.allJournalistData.forEach((email) => {
          if (action.payload.data.Id === email.Id) {
            updateMailData(email)
          }
        })
        state.currentMail = action.payload.data
      })
      .addCase(updateMediaData.fulfilled, (state, action) => {
        //console.log(action.payload.data)
        function updateMailData(email) {
          Object.assign(email, action.payload.data)
        }
        state.allMediaData.forEach((email) => {
          if (action.payload.data.Id === email.Id) {
            updateMailData(email)
          }
        })
        state.allMediaData.filter((email) => {
          if (action.payload.data.Id === email.Id) {
            updateMailData(email)
          }
        })
        state.currentMail = action.payload.data
      })
      .addCase(addData.fulfilled, (state, action) => {
        const allJournalistTemp = state.allJournalistData

        allJournalistTemp.push(action.payload.data)

        state.allJournalistData = allJournalistTemp

        const emailsMetaTemp = state.emailsMeta
        emailsMetaTemp.journalist = allJournalistTemp.filter(
          (email) => !email.isDeleted && email.IsEquipment === true
        ).length
        state.emailsMeta = emailsMetaTemp
      })
      .addCase(getMails.fulfilled, (state, action) => {
        let currMail = null
        if (state.currentMail !== null && state.currentMail !== undefined) {
          currMail = action.payload.data.emails.find(
            (i) => i.id === state.currentMail.id
          )
        }
        state.currentMail = currMail
        state.params = action.payload.params
        state.mails = action.payload.data.emails
      })
      .addCase(getJournalistData.fulfilled, (state, action) => {
        // let currMail = null
        // if (state.currentMail !== null && state.currentMail !== undefined) {
        //   currMail = action.payload.data.emails.find(i => i.id === state.currentMail.id)
        // }
        // state.currentMail = currMail
        // state.params = action.payload.params
        // state.mails = action.payload.allData.emails
        // state.emailsMeta = action.payload.data.emailsMeta
        state.allJournalistData = action.payload.allJournalistData.filter(
          (x) => x.IsEquipment === true
        )
        // console.log(state.allJournalistData)
        state.allMediaData = action.payload.allJournalistDataDDL.filter(
          (x) => x.IsEquipment === false && (x.ParentID === null || x.ParentID === undefined)
        )
        state.allDepartmentData = action.payload.allJournalistDataDDL.filter(
          (x) => x.IsEquipment === false && (x.ParentID !== null && x.ParentID !== undefined)
        )
        state.allGeneralData = action.payload.allJournalistDataDDL.filter(
          (x) => x.IsEquipment === false && (x.IsTriggerAlarm === true)
        )
        // console.log(state.allMediaData)

        state.allJournalistDataDDL = action.payload.allJournalistDataDDL.filter(
          (x) => x.IsEquipment === true
        )
        state.allMediaDataDDL = action.payload.allJournalistDataDDL.filter(
          (x) => x.IsEquipment === false
        )
        state.selectedFolder = action.payload.params.folder
        state.emailsMeta = action.payload.emailsMeta
        state.block = false
      })
      .addCase(updateMails.fulfilled, (state, action) => {
        function updateMailData(email) {
          Object.assign(email, action.payload.dataToUpdate)
        }
        state.mails.forEach((email) => {
          if (action.payload.emailIds.includes(email.id)) {
            updateMailData(email)
          }
        })
      })
      .addCase(paginateMail.fulfilled, (state, action) => {
        const data = action.payload
        const dataIndex = state.mails.findIndex((i) => i.id === data.id)
        dataIndex === 0
          ? (data.hasPreviousMail = false)
          : (data.hasPreviousMail = true)
        dataIndex === state.mails.length - 1
          ? (data.hasNextMail = false)
          : (data.hasNextMail = true)
        state.currentMail = data
      })
      .addCase(selectCurrentMail.fulfilled, (state, action) => {
        state.selectedFolder = action.payload.folder
        state.currentMail = action.payload.data
      })
  }
})

export const { selectMail, selectAllMail, resetSelectedMail } =
  appEmailSlice.actions

export default appEmailSlice.reducer
