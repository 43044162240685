// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

//export const updateData = createAsyncThunk('api/updateDataSource', async payloadData => {
//    console.log("Updating data")
//    const response = await axios.post('/api/user', payloadData.data)
//    return response
//})

//export const addData = createAsyncThunk('api/AddDataSource', async payloadData => {
//    console.log("Adding data")
//    console.log(payloadData.data)
//    const response = await axios.post('/api/user', payloadData.data)
//    return response
//})

// For Listing
export const getData = createAsyncThunk('api/datasourcemaster/view', async params => {
    console.log("getting data from datasourcemaster view")
    const response = await axios.get('/api/datasourcemaster/view', params)
    console.log(response.data)
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

//// For Edit
//export const fetchSingleRecord = createAsyncThunk('api/user/fetch', async params => {
//    console.log(params)
//    const response = await axios.get(`/api/user/${params}`)
//    console.log(response.data)
//    return {
//        data: response.data
//    }
//})

export const appDataSourceSlice = createSlice({
    name: 'appDataSource',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getData.fulfilled]: (state, { payload }) => {
            console.log("get data fulfilled")
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        }
        //[fetchSingleRecord.fulfilled]: (state, { payload }) => {
        //    console.log("fetch data fulfilled")
        //    state.dtoData = payload.data
        //    state.updated = 0
        //},
        //[addData.fulfilled]: (state) => {
        //    console.log("add data fulfilled")
        //    state.updated = 1
        //},
        //[updateData.fulfilled]: (state) => {
        //    console.log("update data fulfilled")
        //    state.updated = 1
        //}
    }
})

export default appDataSourceSlice.reducer
