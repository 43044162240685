// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const addAndUpdateScheduleTasks = createAsyncThunk('api/equipmenttaskandschedule/addupdate', async payloadData => {
    console.log(payloadData.data)
    const response = await axios.post('/api/scheduletask/addandupdatescheduletasks', payloadData.data)
    return response
})

// For Populating of task list in EquipmentTaskAndSchedule
export const getCorrespondingScheduleTasksBasedOnScheduleId = createAsyncThunk('api/equipmenttaskandschedule/fetch', async params => {
    console.log(`schedule id: ${params}`)
    const response = await axios.get(`/api/scheduletask/getcorrespondingscheduletasksbasedonscheduleid/${params}`)
    return {
        data: response.data
    }
})

export const appScheduleTaskSlice = createSlice({
    name: 'appScheduleTask',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getCorrespondingScheduleTasksBasedOnScheduleId.fulfilled]: (state, { payload }) => {
            state.dtoData = payload.data
            state.updated = 0
        },
        [addAndUpdateScheduleTasks.fulfilled]: (state) => {
            state.updated = 1
        }
    }
})

export default appScheduleTaskSlice.reducer
