// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// For Dashboard
export const getTimeToRespondForEngineers = createAsyncThunk('api/workorder/GetTimeToRespondForEngineers', async params => {
    const response = await axios.get(`/api/workorder/GetTimeToRespondForEngineers`, params)
    const payload = [response.data]
    return {
        allData: payload
    }
})

export const appTimeToRespondSlice = createSlice({
    name: 'appTimeToRespond',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getTimeToRespondForEngineers.fulfilled]: (state, { payload }) => {
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        }
    }
})

export default appTimeToRespondSlice.reducer
