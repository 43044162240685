// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// For Dashboard
export const getNumberOfWorkOrdersOpenedForThePast6Months = createAsyncThunk('api/workorder/numberofworkordersopenedforthepast6months', async params => {
    const response = await axios.get(`/api/workorder/GetNumberOfWorkOrdersOpenedForThePast6Months/${params}`)
    const payload = [response.data]
    return {
        allData: payload
    }
})

export const appServiceRequestOpenedMonthlySlice = createSlice({
    name: 'appServiceRequestOpenedMonthly',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getNumberOfWorkOrdersOpenedForThePast6Months.pending]: (state) => {
            state.dtoData = {}
            state.allData = []
            state.updated = 0
        },
        [getNumberOfWorkOrdersOpenedForThePast6Months.fulfilled]: (state, { payload }) => {
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 1
        }
    }
})

export default appServiceRequestOpenedMonthlySlice.reducer
