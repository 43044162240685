// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const addData = createAsyncThunk('api/AddPpmSchedule', async payloadData => {
    console.log(payloadData)
    const response = await axios.post('/api/ppmschedule', payloadData.data)
    return response
})

// To make events appear on calendar
export const fetchEvents = createAsyncThunk('api/ppmschedule/view', async params => {
    const response = await axios.get('/api/ppmschedule/view', params)
    //return {
    //    params,
    //    data: response.data,
    //    allData: response.data,
    //    totalPages: 1
    //}
    //console.log(await axios.get('/apps/calendar/events/Personal'))

    return response.data
})

export const fetchSingleRecord = createAsyncThunk('api/ppmschedule/fetch', async params => {
    console.log("fetching single record")
    console.log(params)
    const response = await axios.get(`/api/ppmschedule/${params}`)
    console.log(response.data)
    return {
        data: response.data
    }
})

//export const addEvent = createAsyncThunk('appCalendar/addEvent', async (event, { dispatch, getState }) => {
//  await axios.post('/apps/calendar/add-event', { event })
//  await dispatch(fetchEvents(getState().calendar.selectedCalendars))
//  return event
//})

//export const updateEvent = createAsyncThunk('appCalendar/updateEvent', async (event, { dispatch, getState }) => {
//  await axios.post('/apps/calendar/update-event', { event })
//  await dispatch(fetchEvents(getState().calendar.selectedCalendars))
//  return event
//})

//export const updateFilter = createAsyncThunk('appCalendar/updateFilter', async (filter, { dispatch, getState }) => {
//  if (getState().calendar.selectedCalendars.includes(filter)) {
//    await dispatch(fetchEvents(getState().calendar.selectedCalendars.filter(i => i !== filter)))
//  } else {
//    await dispatch(fetchEvents([...getState().calendar.selectedCalendars, filter]))
//  }
//  return filter
//})

//export const updateAllFilters = createAsyncThunk('appCalendar/updateAllFilters', async (value, { dispatch }) => {
//  if (value === true) {
//    await dispatch(fetchEvents(['Personal', 'Business', 'Family', 'Holiday', 'ETC']))
//  } else {
//    await dispatch(fetchEvents([]))
//  }
//  return value
//})

//export const removeEvent = createAsyncThunk('appCalendar/removeEvent', async id => {
//  await axios.delete('/apps/calendar/remove-event', { id })
//  return id
//})

export const appCalendarSlice = createSlice({
    name: 'appCalendar',
    initialState: {
        events: [],
        dtoData: {},
        selectedEvent: {},
        selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
    },
    reducers: {
        selectEvent: (state, action) => {
            state.selectedEvent = action.payload
            console.log(state.selectedEvent)
        }
    },
    extraReducers: {
        [fetchEvents.fulfilled]: (state, action) => {
            state.dtoData = []
            state.events = action.payload
            console.log(state.events)
        },
        [fetchSingleRecord.fulfilled]: (state, { payload }) => {
            state.dtoData = payload.data
        },
        [addData.fulfilled]: (state) => {
            state.updated = 1
        }
        //.addCase(updateFilter.fulfilled, (state, action) => {
        //  if (state.selectedCalendars.includes(action.payload)) {
        //    state.selectedCalendars.splice(state.selectedCalendars.indexOf(action.payload), 1)
        //  } else {
        //    state.selectedCalendars.push(action.payload)
        //  }
        //})
        //.addCase(updateAllFilters.fulfilled, (state, action) => {
        //  const value = action.payload
        //  let selected = []
        //  if (value === true) {
        //    selected = ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
        //  } else {
        //    selected = []
        //  }
        //  state.selectedCalendars = selected
        //})
    }
})

export const { selectEvent } = appCalendarSlice.actions

export default appCalendarSlice.reducer
