// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const updateData = createAsyncThunk('api/updateFloor', async payloadData => {
    console.log("Updating data")
    const response = await axios.post('/api/location', payloadData.data)
    return response
})

export const addData = createAsyncThunk('api/AddFloor', async payloadData => {
    console.log("Adding data")
    console.log(payloadData.data)
    const response = await axios.post('/api/location', payloadData.data)
    return response
})

// For Listing
export const getData = createAsyncThunk('api/floor/view', async params => {
    const response = await axios.get('/api/floor/view', params)
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

// For updating floor picklist's options if building were to change 
export const getDataByBuildingId = createAsyncThunk('api/floor/view/filter', async params => {
    const response = await axios.get('/api/floor/view', params)
    console.log(response.data)
    let filteredData = response.data
    filteredData = filteredData.filter(item => {
        return Object.values(item)[5] === params
    })
    console.log(filteredData)
    return {
        allData: filteredData
    }
})

// For Edit
export const fetchSingleRecord = createAsyncThunk('api/floor/fetch', async params => {
    console.log(params)
    const response = await axios.get(`/api/floor/fetchwithfacility/${params}`)
    console.log(response.data)
    return {
        data: response.data
    }
})

export const appFloorSlice = createSlice({
    name: 'appFloor',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getData.fulfilled]: (state, { payload }) => {
            console.log("get data fulfilled")
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        },
        // this is needed for the scenario where u change from a building of 1 floor to another with also 1 floor
        // since the length of allData nvr change, there is a need to reset the allData
        [getDataByBuildingId.pending]: (state) => {
            state.dtoData = {}
            state.allData = []
            state.updated = 0
        },
        [getDataByBuildingId.fulfilled]: (state, { payload }) => {
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        },
        [fetchSingleRecord.fulfilled]: (state, { payload }) => {
            console.log("fetch data fulfilled")
            state.dtoData = payload.data
            state.updated = 0
        },
        [addData.fulfilled]: (state) => {
            console.log("add data fulfilled")
            state.updated = 1
        },
        [updateData.fulfilled]: (state) => {
            console.log("update data fulfilled")
            state.updated = 1
        }
    }
})

export default appFloorSlice.reducer
