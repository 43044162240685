// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// For selectable picklist in redirect functionality
export const getTechniciansInTeam = createAsyncThunk('api/technician/view', async params => {
    console.log("getting technicians in team")
    const response = await axios.get('/api/technician/view', params)
    console.log(response.data)
    if (params !== undefined) {
        console.log(`filtering by ${params}`)
        console.log(typeof params)
        response.data = response.data.filter(item => {
            return Object.values(item)[2] === params
        })
        console.log(response.data)
    } else response.data = {}
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

export const appTechnicianSlice = createSlice({
    name: 'appTechnician',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        // this is needed for the scenario where u change from a team of 1 technician to another with also 1 technician
        // since the length of allData nvr change, there is a need to reset the allData
        [getTechniciansInTeam.pending]: (state) => { 
            state.dtoData = {}
            state.allData = []
            state.updated = 0
            console.log("getTechniciansInTeam pending")
        },
        [getTechniciansInTeam.fulfilled]: (state, { payload }) => {
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
            console.log("getTechniciansInTeam fulfilled")
        }
    }
})

export default appTechnicianSlice.reducer
