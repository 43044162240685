// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// For Dashboard
export const getWorkOrderInfo = createAsyncThunk('api/workorder/info', async params => {
    const response = await axios.get(`/api/workorder/getworkorderinfo/${params}`)
    const payload = [response.data]
    return {
        allData: payload
    }
})

export const appDashboardInfoSlice = createSlice({
    name: 'appDashboardInfo',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getWorkOrderInfo.pending]: (state) => {
            state.dtoData = {}
            state.allData = []
            state.updated = 0
        }, 
        [getWorkOrderInfo.fulfilled]: (state, { payload }) => {
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 1
        }
    }
})

export default appDashboardInfoSlice.reducer
