// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const updateData = createAsyncThunk('api/updateEquipment', async payloadData => {
    const response = await axios.post('/api/equipment/addnewequipment', payloadData.data)
    return response
})

export const addUpdateDataFromExcel = createAsyncThunk('api/AddUpdateEquipmentFromExcel', async payloadData => {
    const response = await axios.post('/api/equipment/AddUpdateEquipmentFromExcel', payloadData)
    return response
})

export const addData = createAsyncThunk('api/AddEquipment', async payloadData => {
    console.log("Adding data")
    console.log(payloadData)
    const response = await axios.post('/api/equipment/addnewequipment', payloadData.data)
    return response
})

// For Listing
export const getData = createAsyncThunk('api/equipment/view', async params => {
    console.log("wrong call")
    const response = await axios.get('/api/equipment/view', params)
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

// For updating equipment picklist's options if location were to change 
export const getDataByLocationId = createAsyncThunk('api/equipment/view/filter', async params => {
    const response = await axios.get('/api/equipment/view', params)
    console.log(response.data)
    let filteredData = response.data
    filteredData = filteredData.filter(item => {
        return Object.values(item)[7] === params
    })
    console.log(filteredData)
    return {
        allData: filteredData
    }
})

// For Edit
export const fetchSingleRecord = createAsyncThunk('api/equipment/fetch', async params => {
    console.log(params)
    const response = await axios.get(`/api/equipment/fetchwithlocation/${params}`)
    console.log(response.data)
    return {
        data: response.data
    }
})

export const appEquipmentSlice = createSlice({
    name: 'appEquipment',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getData.fulfilled]: (state, { payload }) => {
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        },
        // this is needed for the scenario where u change from a level of 1 asset to another with also 1 asset
        // since the length of allData nvr change, there is a need to reset the allData
        [getDataByLocationId.pending]: (state) => {
            state.dtoData = {}
            state.allData = []
            state.updated = 0
        },
        [getDataByLocationId.fulfilled]: (state, { payload }) => {
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        },
        [fetchSingleRecord.fulfilled]: (state, { payload }) => {
            state.dtoData = payload.data
            state.updated = 0
        },
        [addData.fulfilled]: (state) => {
            state.updated = 1
        },
        [addUpdateDataFromExcel.fulfilled]: (state) => {
            state.updated = 1
        },
        [updateData.fulfilled]: (state) => {
            state.updated = 1
        }
    }
})

export default appEquipmentSlice.reducer
