// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const updateData = createAsyncThunk('api/updateWeekoff', async payloadData => {
    const response = await axios.post('/api/weekoff', payloadData.data)
    return response
})

export const addData = createAsyncThunk('api/AddWeekoff', async payloadData => {
    const response = await axios.post('/api/weekoff', payloadData.data)
    return response
})

// For Listing
export const getData = createAsyncThunk('api/weekoff/view', async params => {
    const response = await axios.get('/api/weekoff/view', params)
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

// For Edit
export const fetchSingleRecord = createAsyncThunk('api/weekoff/fetch', async params => {
    console.log(params)
    const response = await axios.get(`/api/weekoff/${params}`)
    console.log(response.data)
    return {
        data: response.data
    }
})

export const appWeekoffSlice = createSlice({
    name: 'appWeekoff',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getData.fulfilled]: (state, { payload }) => {
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        },
        [fetchSingleRecord.fulfilled]: (state, { payload }) => {
            state.dtoData = payload.data
            state.updated = 0
        },
        [addData.fulfilled]: (state) => {
            state.updated = 1
        },
        [updateData.fulfilled]: (state) => {
            state.updated = 1
        }
    }
})

export default appWeekoffSlice.reducer
