// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const updateData = createAsyncThunk('api/updateWorkorderDocument', async payloadData => {
    console.log("Updating data")
    const response = await axios.post('/api/workorderdocument/addnewwodoc', payloadData.data)
    return response
})

export const addData = createAsyncThunk('api/AddWorkorderDocument', async payloadData => {
    console.log("Adding data")
    console.log(payloadData.data)
    const response = await axios.post('/api/workorderdocument/addnewwodoc', payloadData.data)
    return response
})

// For Listing
export const getData = createAsyncThunk('api/workorderdocument/view', async params => {
    const response = await axios.get('/api/workorderdocument/view', params)
    console.log(response.data)
    return {
        params,
        data: response.data,
        allData: response.data,
        totalPages: 1
    }
})

// For downloading uploaded docs
export const downloadWorkOrderDocs = createAsyncThunk('api/downloadWoDoc', async params => {
    console.log(params)
    const response = await axios.get(`/api/workorderdocument/downloadwodoc/${params}`,
        {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            const blobObject = new Blob([response.data]) 
            const responseHeaders = response.headers
            console.log(responseHeaders)
            
            const contentDisposition = responseHeaders["content-disposition"]
            console.log(`content disposition: ${contentDisposition}`)

            let fileName // this will get u both the file name and the extension
            if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                const matches = filenameRegex.exec(contentDisposition)
                if (matches !== null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '')
                }
            }

            console.log(fileName)

            const url = window.URL.createObjectURL(blobObject)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName) //or any other extension
            document.body.appendChild(link)
            link.click()
        }).catch((error) => console.log(error))
    return response
})
        
// For Edit
export const fetchSingleRecord = createAsyncThunk('api/workorderdocument/fetch', async params => {
    console.log(params)
    const response = await axios.get(`/api/workorderdocument/${params}`)
    console.log(response.data)
    return {
        data: response.data
    }
})

export const appWorkorderDocumentSlice = createSlice({
    name: 'appWorkorderDocument',
    initialState: {
        allData: [],
        dtoData: {},
        updated: 0
    },
    reducers: {},
    extraReducers: {
        [getData.fulfilled]: (state, { payload }) => {
            console.log("get data fulfilled")
            state.dtoData = {}
            state.allData = payload.allData
            state.updated = 0
        },
        [fetchSingleRecord.fulfilled]: (state, { payload }) => {
            console.log("fetch data fulfilled")
            state.dtoData = payload.data
            state.updated = 0
        },
        [addData.fulfilled]: (state) => {
            console.log("add data fulfilled")
            state.updated = 1
        },
        [updateData.fulfilled]: (state) => {
            console.log("update data fulfilled")
            state.updated = 1
        }
    }
})

export default appWorkorderDocumentSlice.reducer
